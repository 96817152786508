<!-- Settings -->
<template>
    <div  v-loading="loading">
    <div class="card card-custom">
      <div class="card-body p-0">        
          <div class="row">           
            <div class="col-sm-6 col-xs-12">
              <b-form-group>
                <b-input-group size="sm">
                  <b-form-input
                    v-model="filter"
                    type="search"
                    id="filterInput"
                    placeholder="Type to Search"
                    @keyup.enter.native="(filter)?fetchData(1):''"
                  ></b-form-input>
                  <span class="search-icon" v-if="filter" id="clear-button">
                     <i class="fas fa-times" @click="filter =''"></i>
                     <b-tooltip target="clear-button" variant="primary">Clear</b-tooltip>
                  </span>
                 <b-input-group-append>
                    <b-button :disabled="!filter" @click="(filter)?fetchData(1):''" variant="primary">Search</b-button>
                  </b-input-group-append>
                </b-input-group>
              </b-form-group>
            </div>           
          </div>
        </div>       
          <div class="row">
          <div class="col-sm-12">
            <el-table :data="list" border element-loading-text="Loading" style="width: 100%" v-loading="loading">              
              <el-table-column label="Order #" sortable width="120" >
                <template slot-scope="scope">
                  <el-tag slot="reference" size="mini"><a href="#" @click.prevent="viewOrder(scope.row.order_details.id)">{{(scope.row.order_details.order_number) ? scope.row.order_details.order_number : ""}}</a></el-tag>                
                </template>
              </el-table-column>  
              <el-table-column label="Customer ID" prop="customer_id" sortable width="200" >
                <template slot-scope="scope">
                {{scope.row.customer?scope.row.customer.customer_id:""}}
                </template>
              </el-table-column>           
              <el-table-column label="Customer Name" prop="customer_name" sortable min-width="200" >
                <template slot-scope="scope">
                {{scope.row.customer?scope.row.customer.first_name+' '+(scope.row.customer.last_name == null?"":scope.row.customer.last_name):""}}
                </template>
              </el-table-column>
              <el-table-column label=" Return Date" prop="delivery_date" sortable width="120" >
                <template slot-scope="scope">
                {{scope.row.created_at|formatDate('DD MMM YYYY')}} 
                </template>
              </el-table-column> 
              <el-table-column label="Return Amount" prop="return_amount"  width="140" align="center" >
                <template slot-scope="scope">
                ${{scope.row.total}}
                </template>
              </el-table-column>
             <el-table-column label="Refund Type" prop="refund_type"  width="140" >
                <template slot-scope="scope">
                {{scope.row.return_amount_type?scope.row.return_amount_type:''}}
                </template>
              </el-table-column>     
               <el-table-column label="Return Reason" prop="return_reason"  :min-width="200"  >
                <template slot-scope="scope">
                {{scope.row.return_reason}}
                </template>
              </el-table-column>      
                <el-table-column fixed="right" align="center" label="Actions" width="80">
                <template slot-scope="scope">
                  <el-dropdown @command="handleActions($event,scope.row)" size="medium">
                    <i class="flaticon2-menu-1"></i>
                    <el-dropdown-menu slot="dropdown" class="action-dropdown">
                      <el-dropdown-item :command="1" ><i class="fa fa-eye"></i>View</el-dropdown-item>  
                      <el-dropdown-item :command="4" ><i class="fa fa-download"></i>Download</el-dropdown-item> 
                    </el-dropdown-menu>
                  </el-dropdown>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12">
            <el-pagination v-if="!loading" style="margin-top:15px;"
                background
                layout="sizes, prev, pager, next"
                @size-change="handleSizeChange"
                @current-change="fetchData" 
                :page-count="pagination.last_page" 
                :page-sizes="[10, 25, 50, 100]"
                :page-size="pagination.per_page"
                :current-page.sync="pagination.current_page" 
                :total="pagination.total_rows"
                >
            </el-pagination>
          </div>
        </div>      
      </div>         
<v-dialog/>
    </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {changeStatus, downloadPdf,generateInvoice} from "@/api/order";
import {getAllorder} from "@/api/returnOrder";
import {getAllInvoice} from "@/api/invoice";
import SendEmail from '@/components/SendEmail';
//import GenerateInvoice from '@/components/GenerateInvoice';
import { globalEvents } from '@/utils/globalEvents'
import payNow from '@/components/Payment/payNow';
  export default {
    name: "pos-order",
    data() {
      return {
        list: [],
        items: [],
        pagination:{
          total_rows: 1,
          current_page: 1,
          last_page:null,
          per_page: 10,
        },     
        view_type: 'return_product',
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        filter: null,
        filterOn: [],
        invoice_list :[],     
        isIndeterminate: true,
       
      }
    },
    watch:{
      filter : function(n){
        if(!n){
          this.fetchData(1)
        }
      }
    },
    computed: {
    
    },
    components: {
    },
    mounted() {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: "Dashboard", route: "/dashboard" },
        { title: "Orders", route: "/order/list" },
        { title: "Order Status"},
      ]);

      globalEvents.$on('invoiceAdded', invoice => {
        this.fetchData(1);
        if (invoice) {
            this.fetchData(1);
           
        }
      });
    },
    created(){
      this.fetchData(1);
      this.getInvoice();
    },
    methods:{
        getPaymentStatus(row){
            let date = new Date(row.delivery_date);
            var periods = {
                month: 30 * 24 * 60 * 60 * 1000,
                week: 7 * 24 * 60 * 60 * 1000,
                day: 24 * 60 * 60 * 1000,
                hour: 60 * 60 * 1000,
                minute: 60 * 1000
            };
            var diff = Date.now() - date.getTime();
            var status = "";
            var status_msg = "";
            if(row.payment_status === 'partially_paid'){
                status_msg = '<span class="partially-span el-tag el-tag--mini el-tag--light">Partially Paid</span>';
            }else if(row.payment_status == 'unpaid' && Math.floor(diff / periods.day) > 0){
                if (diff > periods.month) {
                    status = Math.floor(diff / periods.month) + " month ago";
                } else if (diff > periods.week) {
                    status = Math.floor(diff / periods.week) + " week ago";
                } else if (diff > periods.day) {
                    status = Math.floor(diff / periods.day) + " days ago";
                } /*else if (diff > periods.hour) {
                    status = Math.floor(diff / periods.hour) + " hours ago";
                } else if (diff > periods.minute) {
                    status = Math.floor(diff / periods.minute) + " minutes ago";
                }*/
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Over Due</span><div>'+status+'</div>';
            }else if(row.payment_status === 'paid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--success">Paid</span>';
                if(row.term_paid_amount > 0){
                status_msg = '<span class="paid-span el-tag el-tag--mini el-tag--success">Term</span>';
              }
            }else if(row.payment_status === 'unpaid'){
                status_msg = '<span class="el-tag el-tag--mini el-tag--danger">Unpaid</span>';
            }

            return status_msg;
        },
      updateStatus(supplier_id,status){
        changeStatus(supplier_id,status).then(response => {
          this.$showResponse('success', response.data.message);
        })},
          showAndHideColumn(column_name){
        return this.$isColumnShow(column_name, this.checkedColumns);
      },
      fetchData(p) {
        this.loading = true
        let params = { page: p }
        if(this.filter){
          params.filter = this.filter
        }
        if(this.sort){
          params.sort = this.sort;
        }
          if(this.pagination.per_page){
          params.per_page = this.pagination.per_page;
        }
        getAllorder(params).then(response => {         
          this.list = response.data.data.data         
          this.pagination.current_page = response.data.data.current_page
          this.pagination.last_page = response.data.data.last_page
          this.pagination.total_rows = response.data.data.total
          this.loading = false
          this.$set(this.list, '_showDetails', true)
          }
 
        )},
        handleCheckAllChange(val) {
          this.checkedColumns = [];
          if(val){
              this.column_list.map((column,index) =>{
                  this.checkedColumns.push(column.value);
                  console.log(index, this.checkedColumns);
              });
          }
          this.isIndeterminate = false;
      },
      handleCheckedCitiesChange(value) {
          let checkedCount = value.length;
          this.checkAll = checkedCount === this.column_list.length;
          this.isIndeterminate = checkedCount > 0 && checkedCount < this.column_list.length;
      },
        getcustomername(customer){
          if(customer != null){
               return customer.first_name+' '+customer.last_name;
          }           
        },
      viewOrder(order_id){
         this.$router.push({path:'/order/view/'+order_id})        
      },
      onFiltered(filteredItems) {
        this.totalRows = filteredItems.length
        this.currentPage = 1
      },

      handleSizeChange(per_page){
        this.pagination.per_page = per_page;
        this.fetchData(1);
      },
        createOrder(){
        this.$router.push({path:'/order/create'})
      },
      getInvoice(){
        this.loading = true;
         getAllInvoice().then(response => {          
          this.invoice_list = response.data.data;       
          this.loading = false;
        });
      },
     
      handleActions(evt, row)
      {
          if (evt === 1){
            this.$router.push({path:'/return/order/view/'+row.id})
          }
          else if (evt === 2){
            this.$router.push({path:'/order/status/edit/'+row.id})
          }          
          else if (evt === 3){ 
                let customer = row.customer;
                let order_id = row.id;
                let type = this.view_type;

            this.$modal.show(SendEmail, {customer, type, order_id}, 
              { draggable: true, transition: 'pop-out',height: 'auto', clickToClose: false, adaptive: true, width: '500px' })
          } else if (evt === 4){            
            this.loading=true;
            downloadPdf(row.id, this.view_type).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'invoice.pdf');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
          }
         else if (evt === 5){
            this.$bvModal.msgBoxConfirm('Are you sure you want to order cancel ?', {
              title: 'Please Confirm',
              size: 'md',
              buttonSize: 'sm',
              okVariant: 'danger',
              okTitle: 'YES',
              cancelTitle: 'NO',
              footerClass: 'p-2',
              hideHeaderClose: false,
              centered: true
            }).then(value => {
              if(value){ 
                this.$router.push({path:'/cancel/order/'+row.id})
              }else{
                return false;
              }
            })
          }
           else if (evt === 6){   
            this.loading = true;                     
              generateInvoice(row.id).then(response => {         
                this.Invoice = response.data.data 
                  this.$modal.show(payNow, {invoice:this.Invoice}, {
                    draggable: true,
                    transition: 'pop-out',
                    height: 'auto',
                    clickToClose: true,
                    adaptive: true,
                    width: '450px'
                });
                this.loading = false;               
              });  
          
          }else if (evt === 7){
            this.$router.push({path:'/packingslip/create/'+row.id})
          }else if (evt === 8){
            this.$router.push({path:'/invoice/view/'+row.id})
          }  
        },
    },
    
  }
</script>